.acg-logo-wrap{
  max-width: 200px;
}
img{
  width: 100%;
}
.tit-wrap{
  max-width: 250px;
}
.content{
  font-size: 20px;
  font-weight: 500;
  line-height: 1.8;
}