.accIcon1 {
  width: 2rem;
}
.prdBlock1-content{
  top: 50%;
  left: 5%;
}
.prdBlock1-content p{
  color: var(--color_gray);
  font-size: .5rem;
}
.prdB2Content{
  width: 90%;
  max-width: 500px;
}
.prdb2line{
  width: .5px;
  height: 4rem;
  overflow: hidden;
  top: 100%;
}
.recycleIcon{
  margin: 8rem auto 4rem;
  max-width: 5rem;
}
.fourassist{
  width: 60%;
  max-width: 20rem;
}
.prdB4Card{
  background-color: #fff;
  width: 95%;
  max-width: 576px;
  margin: -7rem auto 0;
  box-shadow: 0 0 10px #999;
  border-radius: 1.5rem;
}
.prdB4Card .prdB4CardItem-title{
  width: 30%;

}
.prdB4Card .card-content-divier {
  display: none;
}
.prdB4Card p{
  width: 300px;
  font-size: .8rem;
  font-weight: 300;
}
.prdmenu-list{
  width: 90%;
  max-width: 600px;
  overflow: auto;
}
.prdmenu-list a{
  display: block;
  color: #000;
  width: fit-content;
}
.prd-list .prd-img{
  width: 50%;
  height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}
.prd-list .prd-img img{
  max-width: 180px;
}
.prdB6Card{
  width: 90%;
  margin-bottom: 1.5rem;
  box-shadow: 0 0 20px #999;
  border: unset;
}
.prdB6Card .card-text{
  line-height: 1.5rem;
  font-weight: 500;
  color: #000;
}

@media screen and (min-width:576px) {
  .prdB4Card .card-content-divier {
    display: block;
    height: 5rem;
    border: .5px solid #ccc ;
  }
  .prdB4Card p{
    width: unset;
  }
  .prd-list .prd-img{
    padding: 0 4rem;
    border-left: 1px #ccc solid ;
    border-right: 1px #ccc solid ;
  }
  .prdB6Card{
    width: 28%;
    max-width: 20rem;
  }
}

@media screen and (min-width:768px) {
  .prd-list .prd-img{
    padding: 0 6rem;
  }
}