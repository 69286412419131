:root {
  --color_main: #54be94;
  --color_sec: #18bed2;
  --color_gray: #aaa;
  --color_hover: #f8f8c9;
}
html {
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 16px;
  }
}


/* font */
.fs-7 {
  font-size: 0.75rem;
}

/* bg-color */
.bg-color_main {
  background: var(--color_main);
}

.bg-color_sec {
  background: var(--color_sec);
}

/* color */
.color_main {
  color: var(--color_main);
}

.color_sec {
  color: var(--color_sec);
}

.color_gray {
  color: var(--color_gray);
}

/* btn */
.btn {
  border-radius: 20px;
  padding: 0rem 0.5rem;
}

.btn_main {
  background: var(--color_main);
  color: #ffffff;
}

.btn_sec {
  background: var(--color_sec);
  color: #ffffff;
}

a {
  text-decoration: none;
}
a:hover{
  color: var(--color_hover);
}

figure {
  margin-bottom: 0;
}
.object-fit-contain{
  width: 100%;
  object-fit: contain;
}
/* width */
.mxw-50{
  max-width: 50px;
}
.mxw-100{
  max-width: 100px;
}
.mxw-150{
  max-width: 150px;
}
.mxw-200{
  max-width: 200px;
}
.mxw-300{
  max-width: 300px;
}
.w-unset{
  width: unset;
}
.w-30{
  width: 30%;
}

.pc-view{
  display: none;
}


@media screen and (min-width: 576px) {
  .w-sm-20{
    width: 20%!important;
  }
  .w-sm-25{
    width: 25%!important;
  }
  .w-sm-30{
    width: 30%!important;
  }
  .w-sm-50{
    width: 50%!important;
  }
  .w-sm-unset{
    width: unset!important;
  }

  .pc-view{
    display: flex;
  }
  .m-view{
    display: none;
  }
  
}