main.home {
  margin-top: -80px;
}
.block {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bg-img-0 {
  width: 60%;
  min-width: 300px;
  z-index: -1;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.bg-img-0 img {
  width: 100%;
}

.bg-img-1 {
  background-image: url('../../assets/img/HomePage/block2Bg.svg');
  background-position: center top;
  background-attachment: scroll;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.bg-img-2 {
  background-image: url('../../assets/img/HomePage/block5Bg.svg');
  background-position: center top;
  background-attachment: scroll;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.info-table {
  width: 80%;
  min-width: 80px;
  margin: 1.5rem auto;
}

.info-table > div {
  padding: 0.1rem 0.25rem;
  border-radius: 5px;
}

.info-table > div:nth-child(2n + 1) {
  background-color: #ccc;
}

.info-table > div:last-child {
  background-color: #fff;
}

.icon {
  width: 2rem;
  margin-right: 1rem;
}

.translate50 {
  transform: translate(-50%, -50%);
}
.transition5 {
  transition: 0.5s;
}

#slider3 .section-icon {
  max-width: 2rem;
  margin: 0 auto 1.5rem;
}

#slider3 .content-img img {
  max-width: 45rem;
}

#slider4 figure {
  height: 3rem;
  margin-bottom: 2rem;
}

#slider4 figure img {
  height: 100%;
}

#slider4 p {
  font-size: 0.8rem;
}

#slider6 .section-icon {
  max-width: 3rem;
  max-height: 2rem;
  margin-left: auto;
  margin-right: auto;
}

#slider2,
#slider3,
#slider4,
#slider5,
#slider6 {
  /* opacity: 0; */
  transition: 0.4s;
}
.box1 {
  width: 100px;
  height: 100px;
  background-color: red;
}
.title {
  margin-bottom: 20px;
  padding: 20px;
}
.prd-img {
  width: 100%;
}
.left-50 {
  left: 50%;
}
.owl-carousel a h3 {
  display: none;
}
.mask-dark {
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}
a:hover .mask-dark {
  background-color: #00000060;
}
.owl-carousel a:hover h3 {
  display: block;
}
.owl-carousel {
  margin-bottom: 0.5rem;
}
.owl-dots {
  display: none;
}

.box {
  /* width: 200px; */
  /* height: 180px; */
  /* height: 100%; */
  background: transparent center center no-repeat;
  background-size: cover;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0.25rem;
  height: 10rem;
  /* position: relative; */
}
/* .box img{
  object-fit: cover;
  object-position: center center;
  width: 100%;
  
} */

.box a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #fff;
  background-color: #00000080;
  /* position: absolute; */
}
.box:hover a {
  background-color: #00000060;
}
.box a h3 {
  font-size: 1.2rem;
}

.slick-track {
  height: 100%;
}

.slick-arrow {
  visibility: hidden;
}

.slick-arrow:hover {
  visibility: visible;
}
.homeB7Slider{
  width: 100%;
  height: 0;
  padding-bottom: 54.25%;
  overflow: hidden;
}
.homeB7Slider img{
  width: 100%;
}


@media only screen and (min-width: 768px) {
  html {
    font-size: 16px;
  }


  #slider3 .section-icon {
    max-width: 3rem;
  }

  #slider4 figure {
    height: 6rem;
  }

  #slider4 p {
    font-size: 1rem;
  }

  .info-table {
    width: 60%;
    min-width: 110px;
    margin: 3rem auto;
  }
}
/* .product-intro, .service-intro{
  height: 100vh;
  overflow: auto;
} */
/* 電腦版才會定住 */
@media only screen and (min-width: 1025px) {
  .bg-img-1, .bg-img-2 {
    background-attachment: fixed;
  }
}